import React from "react";
import moment from "moment";

const Comment = (props) => {
  const styles = {
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.5",
    boxSizing: "border-box",
    float: "left!important",
    marginTop: "1.5rem!important",
    textAlign: "justify!important",
    backgroundColor: "#0C0404",  
    // backgroundColor: "#1c2833",
    borderRadius: "7px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
  };
  return (
    <ul>
      {props.comments.map((comment) => (
        <li id={comment.id} key={comment.id}>
          <div style={styles} className="comment">
            <span>
              {moment(comment.created_at).format("dddd, MMMM Do YYYY, hh:mm A")}
            </span>
            <br />
            <p>{comment.comments}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Comment;
