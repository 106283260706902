import React, { useState, useRef, useEffect } from "react";
import axios from "../api/axios";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

let REQUEST_URL = "forgot";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  
  const emailRef = useRef();
  const errRef = useRef();
  const [validEmail, setValidEmail] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const EMAIL_REGEX =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(REQUEST_URL, {"email":email});
      setSuccess(true);
    
    } catch (err) {
        setErrMsg("Errors Encountered !")
      // console.log(err);
    }
  };
  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email,success,errMsg]);
  return (
    <main className="Register">
      {success ? (
        <section className="auth">
          {/* <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
          <p>
            <a href="#">Sign In</a>
          </p> */}
          <h1 className='successmsg'>Check Your Email</h1>
          <Link to="/">Home</Link>
        </section>
      ) : (
        <section className="auth">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          <h1>Forgot Password</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="username">
              Your Email:
              <FontAwesomeIcon
                icon={faCheck}
                className={validEmail ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validEmail || !email ? "hide" : "invalid"}
              />
            </label>
            <input
              type="text"
              id="username"
              ref={emailRef}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby="uidnote"
              onFocus={() => setUserFocus(true)}
              onBlur={() => setUserFocus(false)}
            />
            <p
              id="uidnote"
              className={
                userFocus && email && !validEmail ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              4 to 24 characters.
              <br />
              Must begin with a letter.
              <br />
              Letters, numbers, underscores, hyphens allowed.
            </p>

            <button disabled={!validEmail ? true : false}>
              Get Reset Link
            </button>
          </form>
          <p>
            <span className="line">
              <Link to="/">Home</Link>
              <br/>
            </span>
            <br/>
          </p>
          
          
        </section>
      )}
    </main>
  );
};

export default ForgotPassword;
