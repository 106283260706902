import { Divider, Typography } from "@mui/material";
import React from "react";
import Container from "@mui/material/Container";

const TermsAndConditions = () => {
  const terms = { color: "rgb(0, 175, 240)", textAlign:"justify" };
  return (
    <main
      className="About"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignContent: "center",
          maxWidth: "90rem",
          backgroundColor: "rgba(11, 11, 11, 0.93)",
          boxShadow: "rgba(140, 152, 164, 0.176) 0px 1px 5px 5px",
        }}
      >
        <Container>
          <h1 style={{ color: "#00AFF0" }}>Terms of Use </h1>
          <br />
          <p className="about-text">
            Welcome to our scam reporting app, Whoscams. By accessing or using
            our App, you agree to be bound by these Terms and Conditions
            ("Terms").
          </p>
          <br />
          {/* <Divider style={{width:""}}/> */}
          <Typography style={terms} variant="h6">
            Accuracy of Reports
          </Typography>
          <p>
            We rely on our users to report scams accurately and truthfully.
            However, we cannot verify the accuracy of all reports posted by
            users. Therefore, we cannot guarantee the completeness, accuracy, or
            reliability of the information posted on our App. We are not
            responsible for any errors or omissions in the reports posted by
            users.
          </p>
          <Typography style={terms} variant="h6">
            User Conduct
          </Typography>
          <p>
            By using our App, you agree to use it only for lawful purposes and
            in a manner that does not infringe upon the rights of others. You
            agree not to post any false, defamatory, or misleading information,
            or any information that infringes upon the intellectual property
            rights of others.
          </p>
          <Typography style={terms} variant="h6">
            Limitation of Liability
          </Typography>
          <p>
            We are not liable for any damages or losses arising out of or in
            connection with the use of our App, including but not limited to
            direct, indirect, incidental, consequential, or punitive damages.
            You agree to indemnify and hold us harmless from any claims,
            damages, or losses arising out of your use of the App or your breach
            of these Terms.
          </p>
          <Typography style={terms} variant="h6">
            Modifications to Terms and App
          </Typography>
          <p>
            We may modify these Terms at any time by posting the revised version
            on our App. Your continued use of the App after any such changes
            constitutes your acceptance of the revised Terms. We may also modify
            or discontinue our App at any time without notice.
          </p>
          <br />

          <br />
        </Container>
      </section>

      <div className="flexGrow"></div>
    </main>
  );
};

export default TermsAndConditions;
