import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import Login from "./Login";
import search from "../images/search.svg";
import { Container } from "react-bootstrap";
// import Divider from "@mui/material";
import axios from "../api/axios";
import { useState } from "react";
import { useEffect } from "react";
import IndexSearch from "./IndexSearch";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CardHeader, Divider } from "@mui/material";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";

import { Button } from "@mui/material";
import LoginGoogle from "./LoginGoogle";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const fontStyle = {
  // fontWeight: 400,
  color: "#1DB954",
  fontFamily: "'Dongle', sans-serif",
  fontFamily: "'Montserrat', sans-serif",
  // textSizeAdjust: "100%",
};
const statsStyle = {
  padding: "10px",
  borderRadius: "0.5rem",
  minHeight: "6rem",
  backgroundColor: "#484848",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};
const Landing = () => {
  const [stats, setStats] = useState({});
  const controller = new AbortController();
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  // const togglePersist = () => {
  //   setPersist((prev) => !prev);
  // };
  // useEffect(() => {
  //   localStorage.setItem("persist", persist);
  // }, [persist]);
  // const [phone, setPhone] = useState("");

  const items = [
    {
      title: "Item 1",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      title: "Item 2",
      description:
        "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      title: "Item 3",
      description:
        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];

  useEffect(() => {
    const getStats = async () => {
      try {
        const response = await axios.get("/stats", {
          signal: controller.signal,
        });
        setStats(response.data);
      } catch (err) {
        // if refresh token expires redirect to login page then back to previous route
        // navigate("/login", { state: { from: location }, replace: true });
      }
    };
    getStats();
  }, []);

  return (
    <main className="Home" style={{}}>
      <section
        style={{
          backgroundColor: "rgba(11, 11, 11, 0.93)",
          boxShadow: "rgba(140, 152, 164, 0.176) 0px 1px 5px 5px",
          maxWidth: "90rem",
        }}
      >
        <Box style={{ padding: "0px" }}>
          <Grid
            container
            // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{
                // padding:"25px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={search}
                      alt="My Image"
                      style={{ maxWidth: "80%", maxHeight: "80%" }}
                    />
                  </div>
                  <Typography style={fontStyle}>
                    <h1>
                      <p
                        style={{
                          textAlign: "left",
                          textShadow: "2px 2px 2px rgba(0, 0, 0, 0.0)",
                        }}
                      >
                        {" "}
                        Are you skeptical of that deal ?
                        <br />
                        It could it be a scam !
                        <br />
                        There is only one way to know
                        <br />
                        Search the records
                        <br />
                        Find out
                        <br />
                        Avoid it like plague
                      </p>
                    </h1>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                borderRadius: "10px",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Box>
                <Box
                  style={{
                    padding: "5%",
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "center",
                  }}
                >
                  <Typography style={fontStyle}>
                    Enter the suspicious number to search for scam records
                  </Typography>
                </Box>
                <Box
                  style={{
                    padding: "1%",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <IndexSearch />
                </Box>
              </Box>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={4}
              style={{
                borderRadius: "10px",
                display: "grid",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    padding: "1%",
                    display: "flex",
                    justifyContent: "space-around",
                    textAlign: "center",
                  }}
                >
                  <Typography style={fontStyle}>
                    Log In with your Google account
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgb(51, 51, 51)",
                    borderRadius: "1rem",
                  }}
                  style={
                    {
                      // padding: "5%",
                    }
                  }
                >
                  <LoginGoogle />
                </Box>
              </Box>
            </Grid> */}
          </Grid>
          <br />
          <Container>
            <div style={{ padding: "0.5rem" }}>
              <Box style={{ marginTop: "1rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Box
                      style={{
                        padding: "",
                        // display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Card style={statsStyle}>
                        <Typography
                          variant="h4"
                          style={{
                            fontFamily: "'Dongle', sans-serif",
                            fontFamily: "'Montserrat', sans-serif",
                            color: "#00AFF0",
                          }}
                        >
                          {stats.user_count} Users
                        </Typography>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      style={{
                        padding: "",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Card style={statsStyle}>
                        <Typography
                          variant="h4"
                          style={{
                            fontFamily: "'Dongle', sans-serif",
                            fontFamily: "'Montserrat', sans-serif",
                            color: "white",
                          }}
                        >
                          {stats.report_count} Scam Reports
                        </Typography>
                      </Card>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      style={{
                        padding: "",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Card style={statsStyle}>
                        <Typography variant="h4" style={fontStyle}>
                          {stats.insights_count} Insights
                        </Typography>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Container>
        </Box>
      </section>
    </main>
  );
};

export default Landing;
