import { Outlet } from "react-router-dom"
import Nav from '../components/Nav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import useWindowSize from '../hooks/useWindowSize';
import useAuth from "../hooks/useAuth";
import Nav2 from "../components/Nav2"

const Layout = () => {
    const { width } = useWindowSize();
    const title="WSY ?";
    const{auth}=useAuth();
    return (
        
        <main className="App">
            {/* <Header width={width} title={title}/> */}
            {/* <Nav auth={auth}/> */}
            <Nav2 auth={auth}/>
            <Outlet />
            {/* <br/> */}
            <Footer/>
        </main>
    )
}

export default Layout
