// import React from 'react'
import axios from '../api/axios';
import useAuth  from './useAuth';


const UseRefreshToken = () => {
    const{setAuth}=useAuth();
    const refresh =async ()=>{
        const response=await axios.get('/refresh',{
            withCredentials:true
        });

            setAuth(prev=>{
                
                return { ...prev,roles:response.data.roles  ,
                   accessToken: response.data.token,user:response.data.user }

            });
            return response.data.token;
    }
    return refresh;
  
};

export default UseRefreshToken;