import { useNavigate, Link, useLocation } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
// import AuthContext from "../context/AuthProvider";
// import axios from "../api/axios";
import UseRefreshToken from "../hooks/useRefreshToken";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useLogout from "../hooks/useLogout";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
// import { Button } from "react-bootstrap";
import shield from "../images/shield.png";
import Form from "react-bootstrap/Form";
import axios from "../api/axios";
import Page from "./Page";
import ReactPaginate from "react-paginate";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { CardHeader, Divider } from "@mui/material";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Alerts from "./Alerts";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { MuiTelInput } from "mui-tel-input";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
let REQUEST_URL = "find";
const REPORT_REQUEST_URL = "report";
const SCAMS_URL = "scams";
const defaultSelectOption = document.getElementById("slt");
const label = { inputProps: { "aria-label": "Switch demo" } };
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // minWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  color: "black",
  borderRadius: "15px",
  backgroundColor: "#F5F8FA",
  border: "3px solid rgb(0, 175, 240)",
  minWidth: "350px",
  // borderImage: "linear-gradient(to right,#657786 ,  #4d4b4b) 1",
};

const Home = () => {
  const navigate = useNavigate();
  const refresh = UseRefreshToken();
  const axiosPrivate = useAxiosPrivate();
  const [userDetails, setUserDetails] = useState();
  const location = useLocation();
  const logout = useLogout();
  const [dataIsLoading, setdataIsLoading] = useState(true);
  const [value, setValue] = useState("");
  const [swithStatus, setswithStatus] = useState("Check here if yes");
  const [isScam, setIsScam] = useState(true);
  const [numberSearched, setNumberSearched] = useState("none");
  const [reportCount, setReportCount] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [comments, setComments] = useState("");
  const [scamOptions, setScamOptions] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasComments, setHasComments] = useState(false);
  const [results, setResults] = useState([]);
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState(null);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setMsg("");
    setFormIsValid(true);
    setOpen(true);
  };
  const handleClose = () => {
    setFormIsValid(true);
    setMsg("");
    setOpen(false);
  };
  const [openReportsAlert, setOpenReportsAlert] = useState(true);
  const [numberIsValid, setNumberIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const [msg, setMsg] = useState("");

  const handleQueryPaginatedReports = async (nxt) => {
    if (typeof nxt !== "undefined") {
      let REPORT_REQUEST_URL = nxt;
      const params = {
        phone: value,
      };

      try {
        const qryReportsResponse = await axiosPrivate.get(REPORT_REQUEST_URL, {
          params,
        });
        setHasComments(true);
        setResults(qryReportsResponse.data["results"]);
        setNextPage(qryReportsResponse.data["next_page"]);
        setPrevPage(qryReportsResponse.data["prev_page"]);
        setPage(qryReportsResponse.data["total_pages"]);
      } catch (err) {}
    } else {
      const params = {
        phone: value,
        page: pageNumber,
      };
      try {
        const qryReportsResponse = await axiosPrivate.get(REPORT_REQUEST_URL, {
          params: params,
        });
        setHasComments(true);
        setResults(qryReportsResponse.data["results"]);
        setNextPage(qryReportsResponse.data["next_page"]);
        setPrevPage(qryReportsResponse.data["prev_page"]);
        setPage(qryReportsResponse.data["total_pages"]);
        if (results.length > 0) {
          setOpenReportsAlert(true);
        }
      } catch (err) {}
    }
  };

  const handleOptionsChange = (event) => {
    setSelectedId(event.target.value);
  };
  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const formStyle = {
    width: "500px",
  };

  const inputStyle = {
    width: "100%",
    fontSize: "1rem",
    color: "#555555",
    marginBottom: "20px",
  };
  const signOut = async () => {
    // if used in more components, this should be in context
    // axios to /logout endpoint
    // setAuth({});

    await logout();
    navigate("/");
  };
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    setPage(1);
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/user", {
          signal: controller.signal,
        });
        setdataIsLoading(false);
        setUserDetails(response.data.first_name);
        setComments("");
      } catch (err) {
        // if refresh token expires redirect to login page then back to previous route
        // navigate("/login", { state: { from: location }, replace: true });
        navigate("/landing", { state: { from: location }, replace: true });
      }
    };
    getUsers();

    const getScams = async () => {
      try {
        const scamsResponse = await axiosPrivate.get(SCAMS_URL, {
          signal: controller.signal,
        });
        setScamOptions(scamsResponse.data);
      } catch (err) {}
    };
    getScams();

    const getComments = async () => {
      try {
        const scamsResponse = await axiosPrivate.get(SCAMS_URL, {
          signal: controller.signal,
        });
      } catch (err) {
        navigate("/landing", { state: { from: location }, replace: true });
      }
    };
    getComments();
  }, []);
  useEffect(() => {
    setNumberSearched(value);
    if (typeof value !== "undefined") {
      if (isPossiblePhoneNumber(value)) {
        handleQueryPaginatedReports();
        handleSubmit();
        setNumberIsValid(true);
      } else {
        setNumberIsValid(false);
      }
    }
  }, [value]);

  useEffect(() => {}, [open]);

  const handleSubmit = async () => {
    try {
      const response = await axiosPrivate.post(
        REQUEST_URL,
        {
          phone: value,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setNumberSearched(response.data.phone["phone"]);
      setReportCount(response.data.report_count);
    } catch (e) {}
  };
  const handleIsScam = (e) => {
    setIsScam(!isScam);
    if (isScam === true) {
      setswithStatus("Check here if yes");
      handleOpen();
      setShowForm(false);
    } else {
      setswithStatus("Yes");
      setShowForm(true);
    }
  };
  const handleSumbmitReport = async (e) => {
    if (selectedId === null || comments === "") {
      setMsg("Please Fill In All Fields");
      setFormIsValid(false);
    } else if (selectedId && comments) {
      try {
        const reportResponse = await axiosPrivate.post(REPORT_REQUEST_URL, {
          phone_number: value,
          comments: comments,
          scam: selectedId,
        });
        handleQueryPaginatedReports();
        handleSubmit();
        setFormIsValid(true);
        setComments("");
        setMsg("");
        handleClose();
      } catch (e) {
        const res = JSON.stringify(e);
        setMsg(
          "There Were Errors! Exact Message Cannot Be Posted More Than Once. The Number Must Be Valid"
        );
        setFormIsValid(false);
      }
    }
  };

  return (
    <main
      className="About"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignContent: "center",
          maxWidth: "90rem",
          backgroundColor: "rgba(11, 11, 11, 0.93)",
          boxShadow: "rgba(140, 152, 164, 0.176) 0px 1px 5px 5px",
        }}
      >
        <Container style={{ paddingRight: "0px", paddingLeft: "0px" }}>
          {dataIsLoading && (
            <Stack sx={{ color: "grey.500" }} spacing={2}>
              <LinearProgress color="primary" />
            </Stack>
          )}
          <Box>
            <Card
            // style={{
            //   background:
            //     "linear-gradient(to right, #e1e1e100, rgb(255, 255, 255), transparent)",
            // }}
            >
              <CardContent>
                <div
                  style={{
                    // display: "flex",
                    // gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "space-evenly",
                    justifyContent: "space-evenly",
                    columns: 2,
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    // borderRadius:"inherit",
                  }}
                >
                  <form
                    style={{
                      width: "100%",
                      "@media screen and (min-width: 768px)": {
                        maxWidth: "400px",
                      },
                    }}
                  >
                    <PhoneInput
                      // displayInitialValueAsLocalNumber
                      placeholder="Enter Number"
                      defaultCountry="KE"
                      value={value}
                      onChange={(value) => setValue(value)}
                      limitMaxLength={true}
                    />
                  </form>
                </div>

                {/* <div style={{ display: "flex", justifyContent: "space-around" }}
                  
                >
                  <MuiTelInput
                    defaultCountry="KE"
                    value={value}
                    
                    sx={{ borderRadius: 16 }}
                    onChange={(value) => setValue(value)}
                   
                  />
                </div> */}

                {numberIsValid ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10dpx",
                      color: "rgb(0, 175, 240)",
                    }}
                  >
                    <h3>STATISTICS</h3>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "10dpx",
                      color: "rgb(0, 175, 240)",
                    }}
                  >
                    <h3>Enter Valid Number (e.g +254712345678)</h3>
                  </div>
                )}
              </CardContent>
              <Divider />

              {numberIsValid ? (
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "space-evenly",
                      justifyContent: "space-evenly",
                      columns: 2,
                    }}
                  >
                    <div
                      className="col"
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <ReportProblemIcon
                        style={{
                          minHeight: "50px",
                          minWidth: "50px",
                          color: "#ECB22E",
                        }}
                      />
                      <h3>{reportCount} Reports</h3>
                    </div>
                    <div
                      className="col"
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <ForumOutlinedIcon
                        style={{
                          minHeight: "50px",
                          minWidth: "50px",
                          color: "#1DB954",
                        }}
                      />
                      <h3>{reportCount} Mentions</h3>
                    </div>
                  </div>
                </CardContent>
              ) : (
                <></>
              )}
              <Divider />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    justifyContent: "center",
                    columns: 1,
                  }}
                >
                  {numberIsValid ? (
                    <div>
                      {/* <span
                        // role="button"
                        style={{
                          fontWeight: "",
                          color: "#36C5F0",
                          cursor: "pointer",
                          fontSize: "13px",
                        }}
                      >
                        Report This Number?
                      </span> */}
                      <Button variant="outlined" onClick={() => handleOpen()}>
                        {" "}
                        <span
                          style={{
                            color: "rgb(0, 175, 240)",
                            cursor: "pointer",
                          }}
                        >
                          Report This Number
                        </span>
                      </Button>
                      {/* <Switch onChange={handleIsScam} {...label} /> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </CardContent>
              <Divider />
              {/* <CardContent>
               
              </CardContent> */}
            </Card>
            {results.length > 0 ? (
              <div
                style={{
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#14171A",

                  overflowY: "scroll",
                  maxHeight: "100vh",
                }}
              >
                <Page
                  comments={results}
                  nextPage={nextPage}
                  page={page}
                  prevPage={prevPage}
                  getNext={handleQueryPaginatedReports}
                />
                <Alerts
                  setOpenReportsAlert={setOpenReportsAlert}
                  openReportsAlert={openReportsAlert}
                />
              </div>
            ) : (
              <></>
            )}
            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    style={{
                      fontSize: "22px",
                      fontFamily: "inherit",
                      textAlign: "center",
                    }}
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Fill In The Details
                  </Typography>
                  {!formIsValid ? (
                    <Box>
                      <br />
                      <Alert
                        variant="outlined"
                        severity="info"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "1rem",
                        }}
                      >
                        {msg}
                      </Alert>
                    </Box>
                  ) : (
                    <></>
                  )}
                  <br />

                  <Form id="rptForm">
                    <Form.Group className="frmGroup">
                      <Form.Label>Tell Us How : </Form.Label>
                      <Form.Control
                        id="slt"
                        as="select"
                        onChange={handleOptionsChange}
                        style={{
                          minHeight: "45px",
                          fontSize: "1rem",
                          color: "#555555",
                          borderRadius: "10px",
                          backgroundColor: "#FFFFFF",
                          minWidth: "initial",
                          textAlign: "center",
                          verticalAlign: "center",
                          padding: "10px 0",
                        }}
                      >
                        {scamOptions.map((item) => (
                          <option
                            style={{ padding: " 0 10px" }}
                            key={item.id}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <br />

                    <Form.Group className="">
                      <Form.Label>Your Story </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        style={inputStyle}
                        onChange={handleCommentsChange}
                      />
                    </Form.Group>
                    <br />
                    <Button
                      variant="contained"
                      sx={{ mt: 1, mr: 1 }}
                      style={{ marginTop: "0rem", borderRadius: "20px" }}
                      onClick={handleSumbmitReport}
                    >
                      Submit
                    </Button>
                    <br />
                  </Form>
                </Box>
              </Modal>
            </div>
          </Box>
          {/* <p>
            Your update from Edgewater Federal Solutions, Inc. Edgewater Federal
            Solutions, Inc. Django Developer Applied on January 21, 2023 Thank
            you for your interest in the Django Developer position at Edgewater
            Federal Solutions, Inc. in United States. Unfortunately, we will not
            be moving forward with your application, but we appreciate your time
            and interest in Edgewater Federal Solutions, Inc..
          </p> */}
        </Container>
      </section>
    </main>
  );
};

export default Home;
