import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Personal from "./Personal";
import { useEffect, useContext, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";

const steps = [
  {
    label: "Update Personal Details",
    description: `Please Fill In Your First Name, Last Name And Mobile Phone`,
    stuff: <Personal />,
  },
  {
    label: "Subscriptions",
    description: "Payments for Premium Service will Be Done Here",
    stuff: <Button style={{ color: "blue" }} />,
  },
];

const UpdateProfile = () => {
  const axiosPrivate = useAxiosPrivate();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <main
      className="About"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignContent: "center",
          maxWidth: "90rem",
          backgroundColor: "rgba(11, 11, 11, 0.93)",
          boxShadow: "rgba(140, 152, 164, 0.176) 0px 1px 5px 5px",
        }}
      >
        <Container
          style={{
            background: "white",
            display: "flex",
            justifyContent: "center",
            borderRadius: "inherit",
            padding:"5px",
            // alignContent:"center"

          }}
        >
          <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step style={{ color: "#14171A" ,padding:"0px",margin:"0px", marginLeft:"0px"}} key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    <Box>
                      <div >
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                          style={{ borderRadius: "6rem" }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                        {step.stuff}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </Container>
      </section>
    </main>
  );
};

export default UpdateProfile;
