import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Card } from "@mui/material";
import { CardHeader, Divider } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useState, useEffect } from "react";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import axios from "../api/axios";
import SubjectIcon from "@mui/icons-material/Subject";
import LoginGoogle from "./LoginGoogle";

const IndexSearch = () => {
  const [phone, setPhone] = useState("");
  const [reports, setReports] = useState({});
  const [reportCount, setReportCount] = useState(0);
  const [showStats, setShowStats] = useState(false);
  const [showViewBtn, setShowViewBtn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const toggleLogin = () => {
    setShowLogin(true);
  };
  const fontStyle = {
    // fontWeight: 400,
    color: "#1DB954",
    fontFamily: "'Dongle', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
    // textSizeAdjust: "100%",
  };

  const getReports = async () => {
    const params = {
      phone: phone,
    };
    try {
      const results = await axios.get(
        "/index-reports",
        // {
        //   signal: controller.signal,
        // },
        { params: params }
      );

      if (typeof results !== "undefined") {
        console.log("-----------------------------------------+");
        console.log(JSON.stringify(results));
        console.log("--------------------//---------------------+");
        console.log(JSON.stringify(results["data"]["results"].length));
        setReportCount(results["data"]["results"].length);
        if (results["data"]["results"].length > 0) {
          setShowViewBtn(true);
        } else {
          setShowViewBtn(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const controller = new AbortController();

    if (typeof phone !== "undefined") {
      if (isPossiblePhoneNumber(phone)) {
        getReports();
        setShowStats(true);
      } else {
        setShowStats(false);
        console.log("SAMURAI JACK");
        console.log(phone);
      }
    }
  }, [phone]);

  return (
    <Container style={{ padding: "0px" }}>
      <Box>
        <Card style={{ background: "#EAF2E4", borderRadius: ".5rem" }}>
          <CardContent style={{ marginTop: "1.5rem" }}>
            {/* <Typography>Kinuthia are you there?</Typography> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-evenly",
                justifyContent: "space-evenly",
                columns: 2,
              }}
            >
              <form>
                <PhoneInput
                  style={{
                    width: "100%",
                    "@media screen and (min-width: 768px)": {
                      maxWidth: "400px",
                    },
                  }}
                  placeholder="Enter Number"
                  defaultCountry="KE"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  limitMaxLength={true}
                ></PhoneInput>
              </form>
            </div>
          </CardContent>
          <Divider />
          {showStats ? (
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "space-evenly",
                  justifyContent: "space-evenly",
                  columns: 2,
                }}
              >
                <div
                  className="col"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <GppMaybeIcon
                    style={{
                      minHeight: "4rem",
                      minWidth: "4rem",
                      color: "#ECB22E",
                    }}
                  />
                  <h4>{reportCount} Reports</h4>
                </div>
                <div
                  className="col"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <ForumOutlinedIcon
                    style={{
                      minHeight: "4rem",
                      minWidth: "4rem",
                      color: "#1DB954",
                    }}
                  />
                  <h4>{reportCount} Mentions</h4>
                </div>
              </div>
            </CardContent>
          ) : (
            <></>
          )}
          <Divider />
          <CardContent
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "space-evenly",
              justifyContent: "space-evenly",
              columns: 2,
            }}
          >
            {showViewBtn ? (
              <Fab
                variant="extended"
                size="medium"
                color="success"
                aria-label="add"
                onClick={() => toggleLogin()}
              >
                <SubjectIcon sx={{ mr: 1 }} />
                View Stories
              </Fab>
            ) : (
              <></>
            )}
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={() => toggleLogin()}
            >
              <AddIcon sx={{ mr: 1 }} />
              Report
            </Fab>
          </CardContent>
          <Divider />
          {showLogin ? (
            <CardContent>
              {/* <h5>Please Log In to report and view all reports</h5> */}
              <Typography
                style={{
                  textAlign: "center",
                  fontFamily: "'Dongle', sans-serif",
                  fontFamily: "'Montserrat', sans-serif",
                }}
              >
                Log In with your Google account report and view reports
              </Typography>
              <LoginGoogle />
            </CardContent>
          ) : (
            <>
              <LoginGoogle />
            </>
          )}
        </Card>
      </Box>
    </Container>
  );
};

export default IndexSearch;
