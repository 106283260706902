import React from "react";
import Comment from "./Comment";
import { Button } from "@mui/material";

const Page = (props) => {
  return (
    <div className="comments-page">
      <br/>
      <h2 style={{textAlign:"center",position:"sticky", color:"white", top: 0, background:"#14171A",boxShadow:"rgb(247 27 27 / 83%) 1px 2px 5px"}}>REPORT STORIES & MENTIONS</h2>
      <Comment comments={props.comments} nextPage={props.nextPage} />
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="row"
      >
        {props.prevPage ? (
          <Button  variant="contained" sx={{ mt: 1, mr: 1 }}
          style={{ marginTop: "0rem", maxHeight:"35px", borderRadius: "15px" }} onClick={() => props.getNext(props.prevPage)}>
            {"<"} Previous
          </Button>
        ) : (
          <></>
        )}

        {props.nextPage ? (
          <Button variant="contained"
          sx={{ mt: 1, mr: 1 }}
          style={{ marginTop: "0rem", maxHeight:"35px", borderRadius: "15px" }}   onClick={() => props.getNext(props.nextPage)}>
            Next {">"}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Page;
