import { PhonelinkLockOutlined } from "@mui/icons-material";
import PhoneIcon from '@mui/icons-material/Phone';
import { Typography } from "@mui/material";
const Footer = () => {
    const today = new Date();
    return (
        <footer className='Footer'>
            <Typography   style={{textAlign:"center"}}>
            Whoscams  &copy; {today.getFullYear()} | Contact Us : +254700494222 | support@whoscams.com
            {/* <br></br>
                Paybill: 400200 |  AC: 01192913058400 */}
                

            </Typography>

        </footer>
    )
}

export default Footer
