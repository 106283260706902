import React from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CardHeader, Divider } from "@mui/material";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import { Button } from "@mui/material";
import axios from "../api/axios";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";

const LoginGoogle = () => {
  const controller = new AbortController();
  const { setAuth, persist, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const togglePersist = () => {
    setPersist((prev) => !prev);
  };
  const fontStyle = {
    // fontWeight: 400,
    color: "#1DB954",
    fontFamily: "'Dongle', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
    // textSizeAdjust: "100%",
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      const gResponse = await axios.post(
        "google-auth",
        {
          token: credentialResponse["credential"],
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(JSON.stringify(gResponse));
      const accessToken = gResponse?.data?.token;
      const roles = gResponse?.data?.roles;
      const user = gResponse?.data?.user;
      const pwd = gResponse?.data?.pwd;
      setAuth({ user, pwd, roles, accessToken });
      console.log(JSON.stringify(gResponse));
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container style={{ padding: "0px" }}>
      <Box>
        <Card
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // background: "#EAF2E4",
            borderRadius: ".5rem",
          }}
        >
          <CardContent style={{ marginTop: "0rem" }}>
            <GoogleLogin
              onSuccess={(credentialResponse) =>
                handleGoogleLogin(credentialResponse)
              }
              onError={() => {
                console.log("Login Failed");
                // useOneTap
              }}
            />
          </CardContent>

          <CardContent>
            <form style={{padding:"0rem"}}>
              <div className="persistCheck">
                <input
                  type="checkbox"
                  id="persist"
                  onChange={togglePersist}
                  checked={persist}
                />
                <label htmlFor="persist">
                  <Typography>Keep me logged in</Typography>
                </label>
              </div>
            </form>
          </CardContent>
          <CardContent style={{ padding:"5px"}}>
            <Link to="/terms">
              <Typography style={fontStyle}>Terms of use</Typography>
            </Link>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginGoogle;
