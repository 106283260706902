import { Link } from "react-router-dom"

const Admin = () => {
    return (
        <main className='Admin'>
            <section>
            <h1>Admins Page</h1>
            <br />
            <p>You must have been assigned an Admin role.</p>
            <div className="flexGrow">
                <Link to="/">Home</Link>
            </div>
        </section>

        </main>
    )
}

export default Admin
