import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import Container from "@mui/material/Container";

const About = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  //
  // style={{ height: "100vh" }}
  return (
    <main
      className="About"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignContent: "center",
          maxWidth: "90rem",
          backgroundColor: "rgba(11, 11, 11, 0.93)",
          boxShadow: "rgba(140, 152, 164, 0.176) 0px 1px 5px 5px",
        }}
      >
        <Container>
          <h1 style={{ color: "#00AFF0" }}>About V2. 0323</h1>
          <br />
          <p className="about-text">
            Scammers are constantly finding new ways to take advantage of people
            looking for jobs and romantic partners, often via dating websites, apps or
            social media. These scams can lead to financial losses and emotional
            distress, so it's important to know what to look out for and how to
            protect yourself. Here are some tips to help you avoid scams when
            searching for a romantic partner online:
            <br />
            <br />
            1. Research the person: Before you start communicating with someone
            online, research their name, photos, and online presence. Scammers
            often use stolen photos and fake names.
            <br />
            2. Watch for red flags: Scammers often make unrealistic promises,
            ask for money, or try to move the conversation off the dating
            website too quickly.
            <br />
            3. Take it slow: Scammers often work quickly to build trust and get
            what they want, so it's important to take things slow and not rush
            into a deals. Get to know the person through conversations,
            emails, and video chats before meeting in person or sending money.
            <br />
            4. Use a reputable dating website: Many scams start on free dating
            websites or apps where anyone can create a profile. To reduce your
            risk, use a reputable dating website that has a good reputation, has
            been around for a while, and has a large user base.
            <br />
            5. Be cautious when sending money: Scammers often ask for money,
            either by claiming to be in financial trouble or by using other
            pretexts. Don't send money to someone you've never met in person,
            and be cautious when sending money to someone you've only met
            online.
            <br />
            <br />
            By following these tips, you can reduce your risk of falling victim
            to a scam when looking for a romantic partner online. Remember to
            take things slow, be cautious when sending money, and never give out
            personal information to someone you don't know.
            <br />
            In conclusion, being aware of the dangers and knowing what to look
            out for is the best way to protect yourself from scams when
            searching for a romantic partner online. By using reputable dating
            websites, being cautious when sending money, and reporting scams to
            <br />
            <br />
            <div style={{ color: "#00AFF0" }}>
              <p>
                <strong>
                  If you already found yourself a victim of the scam, share your
                  story on this platform. By doing so you will be helping others
                  avoid similar scams
                </strong>
              </p>
            </div>
          </p>
          <br />

          <h1 style={{ color: "#00AFF0" }}>Disclaimer</h1>
          <br />
          <p>We cannot verify the accuracy of posts made on this platform. See our <span className="line">
                  <Link to="/terms">Terms of use</Link>
                </span></p>
          <br />
        </Container>
      </section>

      <div className="flexGrow"></div>
    </main>
  );
};

export default About;
