import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useRef, useEffect } from "react";
import { Form, FormControl, FormLabel } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { BorderTop } from "@mui/icons-material";

const NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
const UPDATE_DETAILS_URL = "update-user/";

const Personal = () => {
  const axiosPrivate = useAxiosPrivate();
  const [phone, setPhone] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const fNameRef = useRef();
  const lNameRef = useRef();
  const phoneRef = useRef();
  const [fnameFocus, setFnamerFocus] = useState(false);
  const [lnameFocus, setLnamerFocus] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validLName, setValidLName] = useState(false);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validName || !validLName || !phone) {
      setError("Please fill valid first name, last name and phone number");
      return;
    }
    setError("");
    setIsLoading(true);

    try {
      const response = await axiosPrivate.put(
        `${UPDATE_DETAILS_URL}${userId}/`,
        {
          first_name: fname,
          last_name: lname,
          phone: phone,
        }
      );
      
      setIsLoading(false);
    } catch (err) {
      setError("An error occurred while updating details");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValidName(NAME_REGEX.test(fname));
  }, [fname]);
  useEffect(() => {
    setValidLName(NAME_REGEX.test(lname));
  }, [lname]);

  useEffect(() => {
    const controller = new AbortController();
    const getUser = async () => {
      try {
        const response = await axiosPrivate.get("/user", {
          signal: controller.signal,
        });

        setUserId(response.data.id);
        setFname(response.data.first_name);
        setLname(response.data.last_name);
        setPhone(response.data.phone);
        setIsLoading(false);
      } catch (err) {
        // if refresh token expires redirect to login page then back to previous route
        navigate("/landing", { state: { from: location }, replace: true });
      }
    };
    getUser();

    // return () => {
    //   controller.abort();
    // };
  }, []);
  return (
    <Container style={{padding:0}}>
      <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        marginTop:"1rem"
      }}
    >
      <form
        style={{ background: "#F5F8FA", padding: "15px" }}
        onSubmit={handleSubmit}
      >
        {isLoading && (
          <Stack sx={{ color: "grey.500" }} spacing={2}>
            <LinearProgress color="success" />
          </Stack>
        )}

        <label htmlFor="firstName">
          First Name:
          <FontAwesomeIcon
            icon={faCheck}
            className={validName ? "valid" : "hide"}
          />
          <FontAwesomeIcon
            icon={faTimes}
            className={validName || !fname ? "hide" : "invalid"}
          />
        </label>
        <input
          type="text"
          id="firstName"
          ref={fNameRef}
          autoComplete="off"
          onChange={(e) => setFname(e.target.value)}
          value={fname}
          required
          aria-invalid={validName ? "false" : "true"}
          aria-describedby="uidnote"
          onFocus={() => setFnamerFocus(true)}
          onBlur={() => setFnamerFocus(false)}
        />
        <p
          id="uidnote"
          className={
            fnameFocus && fname && !validName ? "instructions" : "offscreen"
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
          4 to 24 characters.
          <br />
          Must begin with a letter.
          <br />
          Letters, numbers, underscores, hyphens allowed.
        </p>
        {/* last name here */}
        <label htmlFor="lastName">
          Last Name:
          <FontAwesomeIcon
            icon={faCheck}
            className={validLName ? "valid" : "hide"}
          />
          <FontAwesomeIcon
            icon={faTimes}
            className={validLName || !lname ? "hide" : "invalid"}
          />
        </label>
        <input
          type="text"
          id="lastName"
          ref={lNameRef}
          autoComplete="off"
          onChange={(e) => setLname(e.target.value)}
          value={lname}
          required
          aria-invalid={validName ? "false" : "true"}
          aria-describedby="uidnote"
          onFocus={() => setLnamerFocus(true)}
          onBlur={() => setLnamerFocus(false)}
        />
        <p
          id="uidnote"
          className={
            lnameFocus && lname && !validName ? "instructions" : "offscreen"
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
          4 to 24 characters.
          <br />
          Must begin with a letter.
          <br />
          Letters, numbers, underscores, hyphens allowed.
        </p>
        <label htmlFor="phoneNumber">Phone Number:</label>
        <PhoneInput
          id="phoneNumber"
          style={{ padding: "0.25rem" }}
          placeholder="Enter phone number"
          value={phone}
          onChange={setPhone}
          limitMaxLength={true}
          defaultCountry="KE"
        />
        <Button variant="contained" onClick={handleSubmit}
          style={{ borderRadius: "6rem" ,marginTop:"1rem" ,}}
          disabled={!validName ? true : false}
        >
          Update Details
        </Button>
      </form>
    </div>
    </Container>
  );
};

export default Personal;
