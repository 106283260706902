// import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Policy, PolicyOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { FaSignOutAlt } from "react-icons/fa";
import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";

const Nav2 = ({}) => {
  // console.log("--------------------??");
  const auth = useAuth();
  // console.log(JSON.stringify(auth["auth"]["user"]));
  // console.log(Object.values(auth).length);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [isLogoutButton, setIsLogoutButton] = useState(true);
  const navigate = useNavigate();
  const logout = useLogout();
  const [hideLogout, setHideLogout] = useState(false);
  const signOut = async () => {
    // if used in more components, this should be in context
    // axios to /logout endpoint
    // setAuth({});
    await logout();
    navigate("/");
    setIsLogoutButton(false);
  };
  useEffect(() => {
    if (auth["auth"]["accessToken"]) {
      setHideLogout(false);
    } else {
      setHideLogout(true);
    }
    // console.log("USER NAV");
    // console.log(JSON.stringify(auth));
  }, [auth]);

  return (
    <AppBar position="static" style={{ backgroundColor: "#333" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <PolicyOutlined
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            style={{ color: "" }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "Open Sans,sans-serif",
              fontWeight: 900,
              //   letterSpacing: '.3rem',
              color: "inherit",
              textDecoration: "none",
            }}
            style={{ color: "rgb(0, 175, 240)" }}
          >
            WhoScams
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{ color: "rgb(0, 175, 240)" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem>
                <li>
                  <Typography>
                    <Link
                      style={{ color: "black", textDecoration: "none" }}
                      to="/"
                    >
                      Home
                    </Link>
                  </Typography>
                </li>
              </MenuItem>
              <MenuItem>
                <li>
                  <Typography>
                    <Link
                      style={{ color: "black", textDecoration: "none" }}
                      to="/profile"
                    >
                      My Account
                    </Link>
                  </Typography>
                </li>
              </MenuItem>
              <MenuItem>
                <li>
                  <Typography>
                    <Link
                      style={{ color: "black", textDecoration: "none" }}
                      to="/about"
                    >
                      About
                    </Link>
                  </Typography>
                </li>
              </MenuItem>
            </Menu>
          </Box>
          <PolicyOutlined sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Open Sans,sans-serif",
              fontWeight: 700,
              //   letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
            style={{ color: "rgb(0, 175, 240)" }}
          >
            WhoScams
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <li>
                <Link style={{ textDecoration: "none" }} to="/">
                  Home
                </Link>
              </li>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <li>
                <Link style={{ textDecoration: "none" }} to="/profile">
                  My Account
                </Link>
              </li>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <li>
                <Link style={{ textDecoration: "none" }} to="/about">
                  About
                </Link>
              </li>
            </Button>
          </Box>
          {auth.auth.user && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Account">
                <IconButton
                  style={{ backgroundColor: "rgb(0, 175, 240)" }}
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  <Avatar
                    style={{ backgroundColor: "rgb(0, 175, 240)" }}
                    alt={auth["auth"]["user"].toUpperCase()}
                    src="/static/images/avatar/2.jpg"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography>
                    <Link
                      style={{ color: "black", textDecoration: "none" }}
                      to="/profile"
                    >
                      Profile
                    </Link>
                  </Typography>
                </MenuItem>
                <MenuItem onClick={(e) => signOut()}>
                  <Typography>Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
          {!Object.keys(auth).length && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography>
                    <Link
                      style={{ color: "black", textDecoration: "none" }}
                      // to="/login"
                      to="/landing"
                    >
                      Login
                    </Link>
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Nav2;
